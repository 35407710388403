import React from 'react'

function NotFound() {
    return (
        <div className="private-layout">
            Not Found
        </div>
    );
}

export default NotFound