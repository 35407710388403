import React from "react"
import { Card, Container, Row, Col, ListGroup, ListGroupItem, Jumbotron, CardDeck } from "react-bootstrap"
import { Link } from "react-router-dom"
import { run as runHolder } from 'holderjs/holder';

export default function Dashboard() {
  runHolder('image-class-name-no-initial-dot');
  
  return ( 
    <Jumbotron>
      <Container> 
        <Row>
          <Col md={12}>
            <CardDeck>
              <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src="holder.js/100px180?text=FREE Package" />
                <Card.Body>
                  <Card.Title>Initial evaluation</Card.Title>
                  <Card.Text>
                    Not sure if this program is for you? Pick this free evaluation and decide afterwards
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>&raquo; It takes 5 minutes</ListGroupItem>
                  <ListGroupItem>&raquo; You'll get the results of the evaluation in 24h</ListGroupItem>
                  <ListGroupItem>&raquo; Completely FREE</ListGroupItem>
                  <ListGroupItem>&nbsp;</ListGroupItem>
                  <ListGroupItem>&nbsp;</ListGroupItem>
                </ListGroup>
                <Card.Body className="text-center">
                  <Link to="/choose-program?package=free" className="btn btn-primary">I want this FREE package!</Link>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src="holder.js/100px180?text=3 Months Program" />
                <Card.Body>
                  <Card.Title>3 Months Program</Card.Title>
                  <Card.Text>
                    This is the most used program so far. It includes most of our services and it's best package as value on the market.
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>&raquo; Initial evaluation</ListGroupItem>
                  <ListGroupItem>&raquo; 3 Months Diet Plan</ListGroupItem>
                  <ListGroupItem>&raquo; Monthly evaluation</ListGroupItem>
                  <ListGroupItem>&raquo; Private chat with your doctor</ListGroupItem>
                  <ListGroupItem>&raquo; Video &amp; audio calls with your doctor</ListGroupItem>
                </ListGroup>
                <Card.Body className="text-center">
                  <Link to="/choose-program?package=3months" className="btn btn-primary">
                    <i className="lt">500&euro;</i> - <strong>Only 390&euro;</strong>
                  </Link>
                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
        </Row>      
      </Container>
    </Jumbotron>
  )
}