import React, { useState } from 'react'
import { Jumbotron, Form, Button, Container, Col, Row } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"
import { db } from "../services/firebase"
import { Redirect } from "react-router-dom"

function ChooseProgram(props) {
  const [loading, setLoading] = useState(false)
  const { currentUser, currentPackage, setCurrentPackage } = useAuth()
  const defaultPackage = new URLSearchParams(props.location.search).get("package")

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target)
    setLoading(true)

    data.set('userId', currentUser.toJSON().uid)
    const res = await db.collection('packages').add(Object.fromEntries(data))
    setCurrentPackage(res)
    props.history.push('/')
  }
  
  return (
    !currentPackage.length ? (
      <Jumbotron>
        <Container>
          <Form onSubmit={handleSubmit}>
            <fieldset>
              <Form.Group as={Row} id="package">
                <Form.Label as="legend" column sm={2}>Package</Form.Label>
                <Col sm={10}>
                  <Form.Check type="radio" name="package" value="free" label="Free" defaultChecked={defaultPackage === 'free'} required />
                  <Form.Check type="radio" name="package" value="3months" label="3 Months" defaultChecked={defaultPackage === '3months'} required />
                </Col>              
              </Form.Group>
            </fieldset>
            <fieldset>
              <Form.Group as={Row} id="package">
                <Form.Label as="legend" column sm={2}>Gender</Form.Label>
                <Col sm={10}>
                  <Form.Check type="radio" name="gender" value="Female" label="Female" required />
                  <Form.Check type="radio" name="gender" value="Male" label="Male" required />
                  <Form.Check type="radio" name="gender" value="Other" label="Other" required />
                </Col>              
              </Form.Group>
            </fieldset>
            <Form.Group as={Row} id="height">
              <Form.Label column sm={2}>Age (years)</Form.Label>
              <Col sm={10}>
                <Form.Control type="number" name="age" min="18" max="90" required autoFocus />
              </Col>            
            </Form.Group>
            <Form.Group as={Row} id="height">
              <Form.Label column sm={2}>Height (cm)</Form.Label>
              <Col sm={10}>
                <Form.Control type="number" min="100" max="220" name="height" required placeholder="" />
              </Col>            
            </Form.Group>
            <Form.Group as={Row} id="weight">
              <Form.Label column sm={2}>Weight (kg)</Form.Label>
              <Col sm={10}>
                <Form.Control type="number" min="40" max="200" name="weight" required placeholder="" />
              </Col>            
            </Form.Group>
            <Form.Group as={Row} id="ideal_weight">
              <Form.Label column sm={2}>Ideal Weight (kg)</Form.Label>
              <Col sm={10}>
                <Form.Control type="text" name="ideal_weight" required placeholder="" />
              </Col>            
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={{ span: 10, offset: 2 }}>
                <Button disabled={loading} type="submit">
                  Subscribe
                </Button>
              </Col>
            </Form.Group>          
          </Form>
        </Container>
      </Jumbotron>
    ) : <Redirect to="/" />
  );
}

export default ChooseProgram