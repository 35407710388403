import React from "react"
import { Route } from "react-router-dom"
import AuthLayout from "./AuthLayout"

export default function AuthRoute({ component: Component, ...rest }) {
  
  return (
    <Route
      {...rest}
      render={props => {
        return <AuthLayout><Component {...props} /></AuthLayout>
      }}
    ></Route>
  )
}