import React from 'react'

import PrivateLayoutHeader from './PrivateLayoutHeader'

function PrivateLayout({children}) {
    return (
        <div className="private-layout">
            <PrivateLayoutHeader />
            <section id="main">
                {children}
            </section>
        </div>
    );
}

export default PrivateLayout