import React, { useState } from 'react'
import { Navbar, Nav, NavDropdown, Alert } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { useHistory } from "react-router-dom"
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

function PrivateLayoutHeader() {
    
  const [error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError("")
    
        try {
          await logout()
          history.push("/login")
        } catch {
          setError("Failed to log out")
        }
      }
      
    return (
      <>
        <Navbar bg="light" expand="lg" fixed="top">
            <Link to="/" className="navbar-brand">Telemedicine.ro</Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#link">Nutrition plan</Nav.Link>                  
                </Nav>
                <Nav className="inline">
                <NavDropdown 
                    title={
                        <div className="inline-block">
                            <img className="thumbnail-image" 
                                src="https://vle.hrc.ac.uk/pluginfile.php/54564/mod_page/content/25/default%20profile%20picture.png" 
                                alt="user pic"
                            />

                {currentUser.email}
                        </div>
                    }
                >
                        <NavDropdown.Item eventKey="1.1" href="/profile">Profile</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={handleLogout}>
                          <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                
            </Navbar.Collapse>
        </Navbar>
        {error && <Alert variant="danger">{error}</Alert>}
        </>
    );
}

export default PrivateLayoutHeader