import React, { useContext, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { auth, db } from "../services/firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [currentPackage, setCurrentPackage] = useState()
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  async function getCurrentPackage(user, callback) {
    if(user) {
      const response = db.collection('packages').where('userId', '==', user.toJSON().uid)
      const data = await response.get()
      setCurrentPackage(data.docs)
    }
    
    callback()
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {      
      getCurrentPackage(user, () => {
        setCurrentUser(user)
        setLoading(false)
        if(user) {
          history.push("/")
        }
      })      
    })

    return unsubscribe
  }, [history])

  const value = {
    currentUser,
    currentPackage,
    setCurrentPackage,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : 'Loading ...'}
    </AuthContext.Provider>
  )
}