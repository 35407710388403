import React from "react"
import Signup from "./Signup"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./Dashboard"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import AuthRoute from "./AuthRoute"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import ChooseProgram from "./ChooseProgram"
import NotFound from "./NotFound"

function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute path="/update-profile" component={UpdateProfile} />
            <PrivateRoute path="/choose-program" component={ChooseProgram} />
            <AuthRoute path="/signup" component={Signup} />
            <AuthRoute path="/login" component={Login} />
            <AuthRoute path="/forgot-password" component={ForgotPassword} />
            <Route path="*" component={NotFound} />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App